function speakersSearch() {
    if (document.querySelectorAll('#speakerData')) {
        console.log('Speakers data found');

        document.getElementById('searchInput').addEventListener('input', function() {
            var searchText = this.value.toLowerCase();
            var posts = document.querySelectorAll('#speakerdata > a')
            
            posts.forEach(function(post) {
                var title = post.getAttribute('data-name').toLowerCase()
                var info = post.getAttribute('data-info').toLowerCase()
                var cat = post.getAttribute('data-category').toLowerCase()
                
                if (title.includes(searchText) || info.includes(searchText) || cat.includes(searchText)) {
                    post.style.display = 'block'
                } else {
                    post.style.display = 'none'
                }
            })
        })
    }
}

export default speakersSearch;